import React from "react";
import { Route, Switch, NavLink, BrowserRouter as Router } from "react-router-dom";
import HomePage from "./Home";
import ResumePage from "./Resume";
import CommunityPage from "./Community";
import InfoPage from "./Info";
import header from "./images/header.jpg";
import './App.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const activeStyle = { color: "#F15B2A" };

  return (
    <div className="App">
      <header className="App-header">
        <img src={header} alt="logo" />
        <Router>
          <nav>
            <NavLink to="/" activeStyle={activeStyle} exact>Home</NavLink>{" | "}
            <NavLink to="/resume" activeStyle={activeStyle}>Resume</NavLink>{" | "}
            <NavLink to="/community" activeStyle={activeStyle}>Community</NavLink>{" | "}
            <NavLink to="/info" activeStyle={activeStyle}>Info</NavLink>
          </nav>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/resume" component={ResumePage} />
            <Route path="/community" component={CommunityPage} />
            <Route path="/info" component={InfoPage} />
          </Switch>
        </Router>
        <ToastContainer autoClose={3000} hideProgressBar />
        <span className="Text-Green-on-White">Office: 317-259-6000</span>
        <span className="Text-Green-on-White">Voice Mail: 317-216-3610 Fax: 317-252-4663</span>
        <span className="Text-Green-on-White">3405 E. 86th St. / Indianapolis, IN 46240</span>
        <hr />
        <span className="Text-Green-on-White-Small">Tommy Roylance represents the <a href="https://www.talktotucker.com">F.C. Tucker Company</a></span>
        <span className="Text-Green-on-White-Small">Indiana's Largest Real Estate Company - Since 1918</span>
        <span className="Text-Green-on-White-Small">Copyright &copy; 2021 Tommy Roylance</span>
      </header>
    </div>
  );
}

export default App;
