import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextInput from "./components/TextInput";
import SelectInput from "./components/SelectInput";
import { toast } from 'react-toastify';

const InfoPage = () => {
    const [_errors, _setErrors] = useState({});
    const [_requestType, _setRequestType] = useState('');
    const [_specificArea, _setSpecificArea] = useState('');
    const [_bedrooms, _setBedrooms] = useState('');
    const [_baths, _setBaths] = useState('');
    const [_sqFt, _setSqFt] = useState('');
    const [_priceRange, _setPriceRange] = useState('');
    const [_name, _setName] = useState('');
    const [_address, _setAddress] = useState('');
    const [_city, _setCity] = useState('');
    const [_state, _setState] = useState('');
    const [_zip, _setZip] = useState('');
    const [_country, _setCountry] = useState('USA');
    const [_email, _setEmail] = useState('');
    const [_homePhone, _setHomePhone] = useState('');
    const [_workPhone, _setWorkPhone] = useState('');
    const [_fax, _setFax] = useState('');
    const [_urgent, _setUrgent] = useState('');

    // All form onChange events are handled here
    const onChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case "name":
                _setName(value);
                _setErrors({ 'name': null });
                break;

            case "requestType":
                _setRequestType(value);
                _setErrors({ 'requestType': null });
                break;

            case "specificArea":
                _setSpecificArea(value);
                _setErrors({ 'specificArea': null });
                break;

            case "bedrooms":
                _setBedrooms(value);
                _setErrors({ 'bedrooms': null });
                break;

            case "baths":
                _setBaths(value);
                _setErrors({ 'baths': null });
                break;

            case "sqFt":
                _setSqFt(value);
                _setErrors({ 'sqFt': null });
                break;

            case "priceRange":
                _setPriceRange(value);
                _setErrors({ 'priceRange': null });
                break;

            case "address":
                _setAddress(value);
                break;

            case "city":
                _setCity(value);
                break;

            case "state":
                _setState(value);
                break;

            case "zip":
                _setZip(value);
                break;

            case "country":
                _setCountry(value);
                break;

            case "email":
                _setEmail(value);
                _setErrors({ 'email': null });
                break;

            case "country":
                _setCountry(value);
                break;

            case "homePhone":
                _setHomePhone(value);
                _setErrors({ 'homePhone': null });
                break;

            case "workPhone":
                _setWorkPhone(value);
                break;

            case "urgent":
                _setUrgent(value);
                break;

            default:
                break;
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
        console.log(event);
        console.log("name: ", _name);
        console.log("requestType: ", _requestType);

        if (!_requestType) {
            _setErrors({ 'requestType': "Please select request type" });
            return;
        }

        if (!_specificArea) {
            _setErrors({ 'specificArea': "Please select specific area" });
            return;
        }

        if (!_bedrooms) {
            _setErrors({ 'bedrooms': "Please select bedrooms" });
            return;
        }

        if (!_baths) {
            _setErrors({ 'baths': "Please select baths" });
            return;
        }

        if (!_sqFt) {
            _setErrors({ 'sqFt': "Please select square feet" });
            return;
        }

        if (!_priceRange) {
            _setErrors({ 'priceRange': "Please select price rance" });
            return;
        }

        if (!_name) {
            _setErrors({ 'name': "Please include your name" });
            return;
        }

        if (!_email) {
            _setErrors({ 'email': "Please include your email address" });
            return;
        }

        if (!_homePhone) {
            _setErrors({ 'homePhone': "Please include your home phone" });
            return;
        }

        // POST to the API
        let body = {
            requestType: _requestType,
            specificArea: _specificArea,
            bedrooms: _bedrooms,
            baths: _baths,
            sqFt: _sqFt,
            priceRange: _priceRange,
            name: _name,
            address: _address,
            city: _city,
            state: _state,
            zip: _zip,
            country: _country,
            email: _email,
            homePhone: _homePhone,
            workPhone: _workPhone,
            urgent: _urgent
        };

        fetch('https://pwzuahhm87.execute-api.us-east-1.amazonaws.com/default/log_event', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success: ', data);

                _setRequestType('');
                _setSpecificArea('');
                _setBedrooms('');
                _setBaths('');
                _setSqFt('');
                _setPriceRange('');
                _setName('');
                _setAddress('');
                _setCity('');
                _setState('');
                _setZip('');
                _setCountry('USA');
                _setEmail('');
                _setHomePhone('');
                _setWorkPhone('');
                _setFax('');
                _setUrgent('');
                _setErrors({});

                toast.success('Your request has been sent.');
            })
            .catch(error => {
                console.error('Error: ', error);
                toast.error('Error sending request');
            })


    };

    return (
        <>
            <br />
            <span className="smaller-text">I would love to hear from you about your real estate needs.  Please fill in the following information and I will respond to your request as soon as possible.</span>
            <form onSubmit={onSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <SelectInput
                                    name="requestType"
                                    label="What type of real estate can I help you with?"
                                    value={_requestType}
                                    defaultOption="Select Request Type"
                                    options={[
                                        { value: "singleFamily", text: "Single Family Home" },
                                        { value: "townhomeCondo", text: "Townhome/Condo" },
                                        { value: "vacantLand", text: "Vacant Land" },
                                        { value: "investmentRental", text: "Investment or Rental Property" }
                                    ]}
                                    onChange={onChange}
                                    error={_errors.requestType}
                                />
                            </td>
                            <td colSpan="2">
                                <SelectInput
                                    name="specificArea"
                                    label="Do you prefer a specific area?"
                                    value={_specificArea}
                                    defaultOption="Please select a city"
                                    options={[
                                        { value: "carmelIN", text: "Carmel, IN" },
                                        { value: "zionsvilleIN", text: "Zionsville, IN" },
                                        { value: "geistIN", text: "Geist, IN" },
                                        { value: "fishersIN", text: "Fishers, IN" },
                                        { value: "indianapolisIN", text: "Indianapolis, IN" },
                                        { value: "brownsburgIN", text: "Brownsburg, IN" },
                                        { value: "hamiltonCountyIN", text: "Hamilton County, IN" },
                                        { value: "(other)", text: "(Other)" }
                                    ]}
                                    onChange={onChange}
                                    error={_errors.specificArea}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <SelectInput
                                    name="bedrooms"
                                    label="Bedrooms?"
                                    value={_bedrooms}
                                    defaultOption="How many bedrooms?"
                                    options={[
                                        { value: "2-beds", text: "2 Bedrooms" },
                                        { value: "3-beds", text: "3 Bedrooms" },
                                        { value: "4-beds", text: "4 Bedrooms" },
                                        { value: "5+-beds", text: "5 or more Bedrooms" },
                                    ]}
                                    onChange={onChange}
                                    error={_errors.bedrooms}
                                />
                            </td>
                            <td>
                                <SelectInput
                                    name="baths"
                                    label="Baths?"
                                    value={_baths}
                                    defaultOption="How many baths?"
                                    options={[
                                        { value: "1-2-baths", text: "1-2 Baths" },
                                        { value: "2-3-baths", text: "2-3 Baths" },
                                        { value: "4+-baths", text: "4 or more Baths" },
                                    ]}
                                    onChange={onChange}
                                    error={_errors.baths}
                                />
                            </td>
                            <td>
                                <SelectInput
                                    name="sqFt"
                                    label="Square Feet?"
                                    value={_sqFt}
                                    defaultOption="Square Feet?"
                                    options={[
                                        { value: "under-1500", text: "Less than 1500" },
                                        { value: "1500-2000", text: "1500-2000" },
                                        { value: "2000-2500", text: "2000-2500" },
                                        { value: "2500-3000", text: "2500-3000" },
                                        { value: "3000-5000", text: "3000-5000" },
                                        { value: "5000+", text: "5000+" },
                                    ]}
                                    onChange={onChange}
                                    error={_errors.sqFt}
                                />
                            </td>
                            <td>
                                <SelectInput
                                    name="priceRange"
                                    label="Price Range?"
                                    value={_priceRange}
                                    defaultOption="Price Range?"
                                    options={[
                                        { value: "under-100K", text: "Under $100,000" },
                                        { value: "100-125", text: "$100,000-$125,000" },
                                        { value: "125-150", text: "$125,000-$150,000" },
                                        { value: "150-175", text: "$150,000-$175,000" },
                                        { value: "175-200", text: "$175,000-$200,000" },
                                        { value: "200-250", text: "$200,000-$250,000" },
                                        { value: "250-300", text: "$250,000-$300,000" },
                                        { value: "300-500", text: "$300,000-$500,000" },
                                        { value: "500-700", text: "$500,000-$700,000" },
                                        { value: "700+", text: "$700,000+" },
                                    ]}
                                    onChange={onChange}
                                    error={_errors.priceRange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <TextInput
                                    name="name"
                                    label="First and Last Name"
                                    value={_name}
                                    onChange={onChange}
                                    error={_errors.name}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <TextInput
                                    name="address"
                                    label="Address"
                                    value={_address}
                                    onChange={onChange}
                                    error={_errors.address}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextInput
                                    name="city"
                                    label="City"
                                    value={_city}
                                    onChange={onChange}
                                    error={_errors.city}
                                />
                            </td>
                            <td>
                                <TextInput
                                    name="state"
                                    label="State"
                                    value={_state}
                                    onChange={onChange}
                                    error={_errors.state}
                                />
                            </td>
                            <td>
                                <TextInput
                                    name="zip"
                                    label="Zip"
                                    value={_zip}
                                    onChange={onChange}
                                    error={_errors.zip}
                                />
                            </td>
                            <td>
                                <TextInput
                                    name="country"
                                    label="Country"
                                    value={_country}
                                    onChange={onChange}
                                    error={_errors.country}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                <TextInput
                                    name="email"
                                    label="Email"
                                    value={_email}
                                    onChange={onChange}
                                    error={_errors.email}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <TextInput
                                    name="homePhone"
                                    label="Home Phone"
                                    value={_homePhone}
                                    onChange={onChange}
                                    error={_errors.homePhone}
                                />
                            </td>
                            <td>
                                <TextInput
                                    name="workPhone"
                                    label="Work Phone"
                                    value={_workPhone}
                                    onChange={onChange}
                                    error={_errors.workPhone}
                                />
                            </td>
                            <td>
                                <TextInput
                                    name="fax"
                                    label="FAX"
                                    value={_fax}
                                    onChange={onChange}
                                    error={_errors.fax}
                                />
                            </td>
                            <td>
                                <SelectInput
                                    name="urgent"
                                    label="Urgent Request?"
                                    value={_urgent}
                                    defaultOption="Is this request urgent?"
                                    options={[
                                        { value: "YES", text: "Please contact me as soon as possible." },
                                        { value: "NO", text: "Please contact me at your convenience." },
                                    ]}
                                    onChange={onChange}
                                    error={_errors.urgent}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="submit" className="btn btn-primary">Submit Request</button>
            </form>
            <br />
        </>
    );
}

export default InfoPage;