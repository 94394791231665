import React from "react";
import { Link } from "react-router-dom";

const ResumePage = () => (
    <>
        <p align="center">
            <iframe name="I1" src="https://www.talktotucker.com/tommy.roylance" width="820" height="1157">
                Your browser does not support inline frames or is currently configured not to display inline frames.</iframe></p>
        <br />
    </>
);

export default ResumePage;