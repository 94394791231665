import React from "react";
import { Link } from "react-router-dom";

const CommunityPage = () => (
    <>
        <br />
        <h4 className="Text-Black-on-White">Indianapolis Attractions</h4>
        <div className="Text-Black-on-White">
            <ul className="Smaller-ul-font">
                <li>Arts Council of Indianapolis 631-3301 or 1-800-965-2787</li>
                <li><a href="http://www.childrensmuseum.org/catalog/home.asp">Children's Museum of Indianapolis</a> 924-5431 or 1800-208-5437</li>
                <li>Clowes Memorial Hall 940-9696</li>
                <li><a href="http://www.connerprairie.org/">Conner Prairie Pioneer Settlement (Fishers)</a> 776-6000 or 1-800-966-1836</li>
                <li>Dance Kaleidoscope 940-6555</li>
                <li><a href="http://www.eiteljorg.org/">Eiteljorg Museum of American Indian and Western Art</a> 636-9378</li>
                <li><a href="http://www.presidentbenjaminharrison.org/">President Benjamin Harrison Home (Indianapolis)</a> 631-1898</li>
                <li><a href="http://www.ai.org/iac/index.html">Indiana Arts Commission</a> 232-1268</li>
                <li>Indiana Repertory Theater 635-5252</li>
                <li><a href="http://www.ai.org/ism/">Indiana State Museum</a> 232-1637</li>
                <li><a href="http://www.indplsartcenter.org/">Indianapolis Art Center</a> 255-2464</li>
                <li>Indianapolis Ballet Theatre 637-8979</li>
                <li>Indianapolis Civic Theatre 924-6770</li>
                <li>Indianapolis Children's Choir 949-9640</li>
                <li>Indianapolis Museum of Art 923-1331</li>
                <li><a href="http://www.indyorch.org/">Indianapolis Symphony Orchestra</a> 262-1100</li>
                <li><a href="http://www.indyzoo.com/">Indianapolis Zoo</a> 630-2001</li>
            </ul>
        </div>
        <h4 className="Text-Black-on-White">Indianapolis Business &amp; Agencies</h4>
        <div className="Text-Black-on-White">
            <ul className="Smaller-ul-font">
                <li><a href="http://www.in.gov/bmv/">Bureau of Motor Vehicles</a></li>
                <li><a href="http://www.indychamber.com/">Indianapolis Area Chamber of Commerce</a> 464-2200</li>
                <li><a href="http://www.indygov.org/indyparks/">Indianapolis Parks and Recreation</a> 634-9266</li>
                <li><a href="http://www.mibor.net/">Metropolitan Board of Realtors - Listings of Central Indiana Properties</a></li>
                <li><a href="http://www.indygov.org/voters/">Voter Registration</a></li>
                <li><a href="http://www.state.in.us/">Indiana State Agencies</a></li>
            </ul>
        </div>
        <h4 className="Text-Black-on-White">Sports in Indianapolis</h4>
        <div className="Text-Black-on-White">
            <ul className="Smaller-ul-font">
                <li><a href="http://www.indianablast.com/">Indiana Blast/Indiana Blaze (Soccer!)</a></li>
                <li><a href="http://www.ihsaa.org/">Indiana High School Athletic Association</a></li>
                <li><a href="http://www.nba.com/pacers">Indiana Pacers NBA Basketball</a> 263-2100 or 239-5151</li>
                <li><a href="http://www.colts.com/">Indianapolis Colts NFL Football</a> 262-3389</li>
                <li>Indianapolis Ice IHL Hockey 266-1234</li>
                <li><a href="http://www.indyindians.com/">Indianapolis Indians Baseball</a> 269-3545</li>
                <li><a href="http://www.brickyard.com/">The Indianapolis Motor Speedway</a> ("The Greatest Spectacle in Racing!")</li>
                <li>Indianapolis Twisters (Soccer) 231-2870</li>
            </ul>
        </div>
        <h4 className="Text-Black-on-White">Indiana Sites</h4>
        <div className="Text-Black-on-White">
            <ul className="Smaller-ul-font">
                <li><a href="http://www.ci.indianapolis.in.us/">The Official Web Site for the City of Indianapolis and Marion County</a></li>
                <li><a href="http://www.enjoyindiana.com/">Indiana Division of Tourism</a></li>
            </ul>
        </div>
        <br />
    </>
);

export default CommunityPage;