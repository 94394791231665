import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => (
    <>
        <br />
        <h2 className="Text-Black-on-White">Welcome to TalkToTommy.com</h2>
        <div className="Text-Black-on-White">We're here to serve all of your Real Estate needs.</div>
        <a
            className="App-link"
            href="http://www.talktotucker.com/tommy.roylance"
            target="_blank"
            rel="noopener noreferrer"
        >
            Visit Tommy Roylance's Home Page @ F.C. Tucker Company
        </a>
        <br />
    </>
);

export default HomePage;